<template>
    <div class="visa-master-card">
        
        <!-- 头部导航 -->
        <m-header title="信用卡支付"> </m-header>

        <div class="visa-input m-top-30">
            <span class="input-title">持卡人姓名</span>
            <input type="text" v-model="card_name" class="input" placeholder="请输入持卡人姓名">
        </div>
        <div class="visa-input m-top-42">
            <span class="input-title">信用卡号码</span>
            <input type="text" v-model="card_number" @input="onCard($event)" @paste="onPaste($event)"  :maxlength="22" class="input" placeholder="Card Number">
        </div>

        <van-row gutter="20"  class="m-top-42">
            <van-col span="12">
                <div class="visa-input">
                    <span class="input-title">有效截至日期</span>
                    <input type="text"  v-model="card_date" @keyup="onDate($event)" :maxlength="5"  class="input" placeholder="MM/YY">
                </div>
            </van-col>
            <van-col span="12">
                <div class="visa-input">
                    <span class="input-title">安全码</span>
                    <input type="number" v-model="card_cvd" @input="onCvd($event)" class="input" placeholder="CVC">
                </div>
            </van-col>
        </van-row>
        <div class="visa-input m-top-42">
            <span class="input-title">邮编</span>
            <input type="text" v-model="card_postal_code" maxlength="7" class="input" placeholder="请输入邮编">
        </div>
        <div class="continue" @click="onDone()">
            <van-button :loading="load" loading-text="支付中..."  type="primary" block round color="linear-gradient(315deg, #F03C18 0%, #F06448 100%)">确认付款</van-button>
        </div>

    </div>
</template>



<script>
import MHeader  from '@/components/zh/m-header.vue'
import { visa_pay } from '@/api/zh/cart.js'
export default {
    name: 'VisaMaster',
    components:{ MHeader },
    data() {
        return {
            card_name: '',
            card_number: '',
            card_date: '',
            card_cvd: '',
            card_postal_code: '',

            load: false,
        }
    },
    methods: {


        // 卡号事件 
       onCard(e) {
           if(e.data) {
               if(e.target.value.length > 4 && this.card_number[4] != ' ') {
                    this.card_number  = e.target.value.slice(0,4) + ' ' + e.target.value.slice(4)
                }
                if(e.target.value.length > 9 && e.target.value[9] != ' ') {
                   this.card_number  = e.target.value.slice(0,9) + ' ' + e.target.value.slice(9)
                }
                if(e.target.value.length > 14 && e.target.value[14] != ' ') {
                    this.card_number  = e.target.value.slice(0,14) + ' ' + e.target.value.slice(14)
                }
           }else{
               if(e.target.value.length == 5) {
                    this.card_number  = e.target.value.slice(0,4) 
                }
                if(e.target.value.length == 10) {
                   this.card_number  = e.target.value.slice(0,9) 
                }
                if(e.target.value.length == 15) {
                    this.card_number  = e.target.value.slice(0,14)
                }
           }
           
       },
        // 卡号粘贴事件
       onPaste(e) {
           this.card_number = e.clipboardData.getData('Text')
           if(this.card_number.length > 4 && this.card_number[4] != ' ') {
                this.card_number  = this.card_number.slice(0,4) + ' ' + this.card_number.slice(4)
            }
            if(this.card_number.length > 9 && this.card_number[9] != ' ') {
                this.card_number  = this.card_number.slice(0,9) + ' ' + this.card_number.slice(9)
            }
            if(this.card_number.length > 14 && this.card_number[14] != ' ') {
                this.card_number  = this.card_number.slice(0,14) + ' ' + this.card_number.slice(14)
            }
            let number = this.card_number
            setTimeout( () => {
                this.card_number = number
            })
       },
        

       // 有效期事件
       onDate(e) {
           let date = e.target.value
           if(date.length >2 && e.keyCode != 8 && date[2] != '/') {
               this.card_date = date.slice(0, 2) + '/' + date.slice(2)
           }
           if(this.card_date.length > 5) {
               this.card_date = this.card_date.slice(0, 5)
           }
       },

        // 安全码改变事件 
        onCvd(e) {
            if(e.target.value.length >= 4) {
                e.target.value = e.target.value.slice(0, 4)
                this.card_cvd  = e.target.value
            }
        },

         // 邮编事件
        onCode(e) {
            if(e.keyCode != 8) {
                if(e.target.value.length > 3 && e.target.value[3] != ' ') {
                    this.card_postal_code  = e.target.value.slice(0,3) + ' ' + e.target.value.slice(3)
                }
            }else {
                if(e.target.value.length == 4) {
                    this.card_postal_code  = e.target.value.slice(0,3) 
                }
            }
            this.card_postal_code = this.card_postal_code.toUpperCase()
        },
        // 邮编粘贴事件
        codePaste(e) {
            this.card_postal_code = e.clipboardData.getData('Text')
            if(this.card_postal_code.length > 3 && this.card_number[3] != ' ') {
                this.card_postal_code  = this.card_postal_code.slice(0,3) + ' ' + this.card_postal_code.slice(3, 6)
            }
        },


        // 确认支付
        onDone() {
            let params = {
                order_title: this.$route.params.title
            }


            if(this.card_name == '') {
                this.$notify({ type: 'warning', message: '请输入持卡人姓名' })
                return false
            }else {
                params.card_name = this.card_name
            }

            if(this.card_number == '') {
                this.$notify({ type: 'warning', message: '请输入正确的卡号' })
                return false
            }else {
                params.card_number = this.card_number.replace(/\s*/g,"")
            }

            if(this.card_date.length != 5) {
                this.$notify({ type: 'warning', message: '请输入正确的有效日期' })
                return false
            }else {
                params.card_expiry_month = this.card_date.split('/')[0]
                params.card_expiry_year  = this.card_date.split('/')[1]
            }

            if(this.card_cvd == '') {
                this.$notify({ type: 'warning', message: '请输入信用卡CVC安全码' })
                return false
            }else {
                params.card_cvd = this.card_cvd
            }

            // eslint-disable-next-line
            // let reg = /^([a-zA-Z][0-9]){3}$/
            // if(!reg.test(this.card_postal_code.replace(/\s*/g,""))) {
            //     this.$notify({ type: 'warning', message: '邮编格式错误' });
            //     return false
            // }else {
                // params.card_postal_code = this.card_postal_code.replace(/\s*/g,"")
            // }
            if(this.card_postal_code == '') {
                this.$notify({ type: 'warning', message: '请输入邮编' })
                return false
            }else {
                params.card_postal_code = this.card_postal_code
            }
            
            this.load = true
            visa_pay(params).then(res => {
                if(res) {
                    this.$router.replace( {path: '/zh/paystatus', query: { t: this.$route.params.title, status: 1 } } )
                }else{
                    this.$router.replace( {path: '/zh/paystatus', query: { t: this.$route.params.title, status: 2 } } )
                }
            }).finally( () => {
                this.load = false
            })
        }
        
    },
    create() {
        
    },


}
</script>

<style lang="less" scoped>
.visa-master-card{
    width: 100%;
    height: 100%;
    padding: 44px 16px 0;
    background: #fff;
    
    .visa-input {
        width: 100%;
        height: 50px;
        border-radius: 3px;
        border: 2px solid #3284FA;
        box-sizing: border-box;
        position: relative;
        .input-title {
            background: #fff;
            padding: 0 5px;
            height: 18px;
            line-height: 18px;
            color: #333333;
            letter-spacing: 0.5px;
            font-weight: 400;
            font-size: 16px;
            position: absolute;
            top: -12px;
            left: 8px;
        }

        .input {
            width: 100%;
            height: 40px;
            position: relative;
            top: 6px;
            font-size: 16px;
            border: none;
            outline: none;
            appearance: none;
            padding-left: 12px;
            &::placeholder {
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                color: #999;
            }
        }
    }
    .m-top-30 {
        margin-top: 30px;
    }
    .m-top-42 {
        margin-top: 42px;
    }
    .continue{
        width: 300px;
        margin:60px auto 0;
        /deep/ .van-loading {
            margin: 0;
        }
    }
}
</style>